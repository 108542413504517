import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 181.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,181.000000) scale(0.100000,-0.100000)">



<path d="M2176 1481 c-11 -12 -16 -31 -14 -53 2 -27 8 -34 30 -36 18 -2 31 4
42 20 41 58 -11 121 -58 69z"/>
<path d="M1836 1470 c75 -8 184 -43 184 -59 0 -4 -15 -12 -34 -19 -46 -17
-123 -93 -183 -183 -112 -166 -218 -251 -377 -303 -10 -4 -17 -9 -14 -11 9
-10 143 37 219 76 86 45 145 92 244 198 81 85 140 128 202 147 44 13 46 13 90
-17 43 -30 47 -31 97 -20 28 6 62 14 76 17 24 7 23 6 -6 -19 -17 -15 -37 -40
-44 -56 -7 -17 -28 -40 -47 -51 -20 -11 -33 -24 -30 -27 4 -3 13 0 22 7 9 7
19 10 23 6 4 -4 -2 -14 -13 -22 -11 -8 -20 -23 -21 -33 -1 -10 -14 -35 -29
-55 -15 -20 -26 -38 -24 -40 2 -2 60 -17 129 -34 212 -52 362 -110 453 -174
16 -11 30 -18 33 -15 8 8 -114 91 -191 130 -84 43 -240 103 -289 113 -31 5
-35 4 -30 -10 5 -13 -1 -16 -30 -16 l-36 0 33 43 c42 56 123 129 113 102 -19
-48 -19 -55 3 -55 12 0 44 21 71 46 29 28 50 41 50 32 0 -8 -11 -25 -25 -38
-14 -13 -25 -32 -25 -43 0 -26 23 -16 30 13 7 30 30 38 30 11 0 -31 31 -26 65
9 30 31 25 42 -7 14 -16 -16 -18 -15 -24 1 -5 12 2 24 25 42 33 26 41 53 16
53 -9 0 -20 -12 -26 -27 l-11 -28 -11 23 c-15 32 -28 28 -70 -23 -46 -54 -63
-58 -42 -9 29 71 -6 73 -58 4 -32 -43 -57 -63 -57 -45 0 12 35 75 41 75 4 0
21 12 38 26 35 29 43 74 14 74 -15 0 -13 4 7 21 20 17 21 19 5 13 -11 -4 -50
-9 -86 -12 l-66 -4 -63 50 c-34 28 -92 63 -129 78 -57 24 -82 28 -181 30 -114
2 -114 2 -34 -6z m259 -88 l23 -18 -41 -12 c-36 -9 -105 -49 -184 -108 -13 -9
-23 -12 -23 -8 0 14 81 94 123 122 20 13 37 29 37 33 0 16 41 10 65 -9z m285
-107 c0 -12 -33 -55 -41 -55 -5 0 -3 14 6 30 13 26 35 41 35 25z"/>
<path d="M1056 1393 c-4 -4 -36 -6 -72 -5 -62 3 -65 2 -82 -27 -9 -17 -26 -32
-37 -33 -27 -4 -33 -62 -9 -89 45 -50 100 11 60 67 -22 30 -2 44 65 44 55 0
89 15 89 41 0 11 -4 11 -14 2z"/>
<path d="M1120 1306 c-71 -20 -158 -65 -218 -114 l-55 -44 -66 5 c-36 2 -75 8
-85 13 -14 5 -17 4 -12 -4 12 -20 76 -42 139 -49 50 -5 62 -3 88 16 24 18 33
20 45 10 8 -6 17 -10 19 -7 8 7 -6 38 -16 38 -5 0 -9 5 -9 10 0 13 60 50 81
50 8 0 50 -32 93 -71 57 -54 86 -89 112 -141 42 -83 28 -80 -56 9 -72 77 -122
114 -139 104 -10 -6 -1 -17 33 -41 26 -17 70 -61 97 -96 100 -126 235 -223
385 -274 81 -28 112 -24 35 5 -131 49 -245 153 -321 295 -51 94 -124 181 -170
200 -17 7 -29 18 -27 24 8 25 111 55 187 56 52 0 70 3 60 10 -21 14 -146 11
-200 -4z"/>
<path d="M2422 1288 c-16 -16 -15 -48 2 -48 20 0 39 27 32 45 -7 18 -18 19
-34 3z"/>
<path d="M2645 1268 c-25 -13 -45 -38 -45 -55 0 -22 20 -14 30 12 9 24 29 32
43 19 12 -12 -28 -66 -75 -101 -27 -19 -48 -39 -48 -44 0 -13 18 -11 23 4 3
10 9 10 26 0 30 -18 65 -16 84 5 24 27 22 46 -3 30 -16 -10 -22 -10 -32 1 -11
11 -10 14 0 18 27 10 82 65 82 82 0 37 -43 52 -85 29z"/>
<path d="M2803 1255 c-36 -22 -83 -78 -83 -100 0 -4 14 -5 30 -3 29 4 30 4 24
-29 -5 -25 -3 -33 8 -33 21 0 38 21 38 47 0 16 6 23 19 23 10 0 22 7 25 16 4
12 1 14 -14 9 -26 -8 -25 3 5 47 14 20 25 39 25 42 0 14 -38 4 -77 -19z m2
-54 c-8 -17 -20 -31 -25 -31 -21 0 -18 20 7 51 31 37 42 25 18 -20z"/>
<path d="M2890 1258 c-11 -12 -20 -25 -20 -30 0 -11 18 -10 25 2 6 10 45 14
45 5 0 -3 -16 -27 -35 -54 -36 -50 -46 -91 -21 -91 18 0 30 17 44 65 7 22 25
55 42 74 35 39 37 44 11 36 -10 -3 -30 -1 -45 4 -21 9 -29 7 -46 -11z"/>
<path d="M1935 1198 l-29 -23 47 -7 c67 -9 119 -38 140 -79 22 -42 16 -85 -21
-149 -25 -44 -25 -44 -3 -25 38 33 81 116 81 157 0 56 -19 87 -70 113 -73 37
-110 41 -145 13z"/>
<path d="M130 1154 c-38 -26 -50 -45 -50 -81 0 -23 6 -36 21 -44 21 -11 21
-11 -7 -40 -15 -16 -33 -29 -40 -29 -21 0 -28 22 -10 32 30 17 15 55 -17 42
-20 -7 -28 -43 -16 -65 31 -59 98 -43 157 36 22 29 34 51 25 48 -12 -5 -11 2
5 34 11 22 28 45 36 52 9 8 16 17 16 22 0 16 -94 10 -120 -7z m80 1 c0 -3 -16
-25 -35 -51 -19 -25 -35 -50 -35 -55 0 -5 -7 -9 -15 -9 -20 0 -20 49 1 78 20
28 84 56 84 37z"/>
<path d="M549 1116 c-7 -14 -18 -26 -26 -26 -7 0 -13 -8 -13 -18 0 -10 -11
-29 -26 -42 -21 -21 -27 -22 -34 -10 -14 22 -12 40 4 40 21 0 31 21 15 30 -19
12 -49 -6 -96 -58 -47 -53 -62 -52 -37 2 25 52 8 64 -37 26 -35 -28 -36 -29
-32 -6 8 39 -12 38 -44 -2 -35 -45 -53 -77 -24 -43 26 29 27 16 1 -26 -32 -53
-2 -53 44 0 47 54 64 61 41 17 -20 -39 -19 -50 7 -50 13 0 41 20 70 50 26 28
51 50 54 50 3 0 4 -10 2 -22 -2 -14 -12 -24 -28 -28 -37 -9 -34 -44 4 -48 23
-3 36 4 62 32 36 40 50 46 34 17 -26 -50 5 -68 53 -31 14 11 28 20 31 20 3 0
6 -9 6 -20 0 -23 28 -27 49 -7 11 10 14 10 18 0 9 -24 42 -13 84 30 l43 42
-23 -39 c-24 -42 -23 -51 6 -42 19 7 80 86 65 86 -5 0 -8 10 -8 23 1 35 -19
26 -59 -25 -20 -27 -40 -48 -45 -48 -14 0 -13 6 11 51 16 33 18 43 8 55 -11
13 -14 13 -20 1 -5 -9 -18 -12 -33 -10 -19 4 -36 -7 -82 -53 -32 -32 -59 -53
-61 -47 -7 19 27 75 48 80 19 5 27 23 9 23 -5 0 -7 6 -4 13 3 8 -2 19 -10 26
-13 11 -17 9 -27 -13z m124 -88 c-19 -32 -43 -48 -43 -28 0 18 34 59 48 60 11
0 9 -8 -5 -32z"/>
<path d="M980 1116 c-5 -14 -17 -26 -26 -26 -10 0 -14 -6 -11 -14 3 -7 -12
-30 -34 -52 -41 -40 -50 -34 -23 18 23 45 3 61 -33 25 -15 -15 -22 -27 -15
-27 9 0 9 -7 -3 -30 -35 -67 -7 -80 55 -24 40 36 40 36 30 9 -7 -19 -7 -31 0
-40 7 -9 7 -21 0 -39 -12 -33 -5 -65 26 -126 24 -46 63 -101 64 -88 0 4 -11
28 -25 54 -29 55 -33 119 -9 153 22 30 89 61 137 61 44 0 46 8 12 40 -22 21
-28 22 -62 12 -20 -6 -40 -9 -44 -7 -3 3 -12 -2 -19 -10 -7 -8 -19 -15 -26
-15 -11 0 -10 9 6 40 13 26 27 40 40 40 11 0 20 5 20 10 0 6 -6 10 -13 10 -8
0 -13 10 -13 21 1 32 -22 35 -34 5z m50 -126 c0 -13 -79 -65 -87 -56 -7 7 -9
6 42 39 45 31 45 31 45 17z"/>
<path d="M1352 1120 c-50 -4 -95 -10 -99 -13 -10 -10 49 -11 307 -2 143 4 151
5 110 16 -37 9 -166 9 -318 -1z"/>
<path d="M1860 1101 c0 -11 45 -20 140 -28 95 -7 86 4 -16 22 -93 17 -124 18
-124 6z"/>
<path d="M1299 1060 c-7 -5 -11 -11 -9 -13 9 -9 350 6 344 14 -6 11 -317 10
-335 -1z"/>
<path d="M1806 1051 c-13 -5 28 -12 118 -20 75 -8 139 -11 142 -8 18 18 -223
44 -260 28z"/>
<path d="M489 898 c-82 -44 -173 -108 -165 -115 3 -3 17 4 33 15 35 24 121 68
190 96 46 19 50 23 34 33 -15 9 -32 4 -92 -29z"/>
<path d="M1678 431 c-9 -15 -15 -17 -25 -9 -9 7 -13 7 -13 -1 0 -6 6 -14 14
-17 17 -7 9 -44 -10 -44 -8 0 -11 6 -8 15 7 17 -11 21 -22 4 -3 -6 1 -18 9
-27 14 -14 18 -14 41 2 24 15 31 31 27 63 -1 6 3 14 9 18 10 6 15 -15 10 -45
-1 -8 -11 -27 -22 -43 -11 -15 -18 -31 -14 -34 7 -8 36 16 36 31 0 6 8 20 16
31 16 20 16 20 9 -2 -9 -32 13 -29 25 3 13 33 13 35 -2 33 -28 -3 -38 2 -38
21 0 26 -26 26 -42 1z"/>
<path d="M2142 438 c-15 -15 -16 -45 -2 -53 6 -3 7 1 4 10 -7 18 12 45 32 45
10 0 8 -9 -7 -34 -25 -43 -52 -68 -47 -44 1 9 -1 17 -7 17 -5 1 -10 -7 -10
-16 0 -25 33 -28 58 -6 51 43 87 50 67 13 -9 -16 -5 -18 32 -17 25 1 43 7 46
16 3 8 0 11 -7 7 -6 -4 -11 -3 -11 3 0 16 18 22 23 9 3 -8 6 -6 6 5 1 23 -18
22 -45 -4 l-23 -21 6 21 c5 19 -4 26 -29 22 -5 0 -8 8 -8 19 0 16 -7 20 -33
20 -18 0 -38 -5 -45 -12z"/>
<path d="M2363 423 c-10 -16 -23 -32 -30 -36 -7 -4 -13 -16 -13 -25 0 -10 -5
-23 -12 -30 -6 -6 -8 -15 -4 -19 9 -9 56 41 56 60 0 8 7 20 15 27 8 7 12 17 8
23 -3 6 -1 7 5 3 7 -4 12 -1 12 8 0 25 -18 19 -37 -11z"/>
<path d="M1934 425 c-4 -8 -12 -15 -18 -15 -7 0 -23 -11 -36 -25 -13 -14 -28
-25 -34 -24 -6 0 -5 3 2 6 6 2 9 10 6 15 -4 7 -2 8 4 4 7 -4 12 -1 12 8 0 23
-13 20 -40 -9 -28 -29 -18 -35 54 -32 41 2 50 5 52 22 1 11 6 30 9 43 8 24 -2
31 -11 7z"/>
<path d="M1986 406 c-10 -19 -16 -42 -13 -50 8 -21 72 -15 94 9 17 18 17 18 0
5 -15 -11 -20 -11 -35 4 -14 13 -14 20 -4 36 16 26 15 30 -6 30 -10 0 -26 -15
-36 -34z"/>
<path d="M2777 405 c-21 -43 -21 -55 -3 -55 7 0 16 10 19 23 3 12 9 32 12 45
11 35 -10 26 -28 -13z"/>
<path d="M2052 412 c-16 -2 -22 -8 -18 -19 3 -8 6 -17 6 -19 0 -3 10 4 22 16
25 23 23 27 -10 22z"/>
<path d="M1788 399 c-22 -12 -24 -49 -2 -49 8 0 13 4 10 9 -3 5 1 14 9 21 29
24 16 39 -17 19z"/>
<path d="M2385 390 c-10 -11 -15 -23 -11 -28 11 -10 136 -11 136 -1 0 5 5 9
11 9 5 0 7 -4 4 -10 -4 -6 6 -9 22 -9 51 3 63 9 63 35 0 30 -7 30 -38 2 -20
-18 -24 -19 -19 -6 8 22 -11 35 -19 14 -3 -9 -11 -16 -16 -16 -6 0 -8 7 -4 15
7 20 -8 19 -25 -2 -10 -11 -10 -14 -1 -9 6 4 12 2 12 -3 0 -14 -10 -14 -30 -1
-13 9 -13 11 0 21 12 8 12 9 -5 7 -13 -3 -19 -10 -17 -22 3 -13 -1 -17 -12
-13 -8 3 -18 2 -21 -3 -4 -6 -12 -10 -18 -9 -9 0 -8 2 1 6 6 2 10 9 7 13 -3 5
4 7 16 5 17 -4 19 -2 9 10 -16 20 -23 19 -45 -5z"/>
<path d="M2631 392 c-18 -34 -13 -47 9 -27 16 14 19 15 15 4 -5 -13 3 -16 42
-15 29 1 48 6 48 13 0 6 3 15 8 19 12 13 8 24 -10 24 -9 0 -28 -10 -41 -22
l-24 -23 6 23 c8 23 -7 31 -20 11 -5 -8 -9 -8 -14 1 -6 9 -11 7 -19 -8z"/>
<path d="M2812 393 c-19 -34 -15 -40 25 -39 31 1 38 5 44 29 6 23 5 28 -9 25
-10 -2 -16 -11 -14 -21 4 -24 -22 -17 -29 8 -5 20 -6 19 -17 -2z"/>
<path d="M2901 392 c-18 -34 -13 -47 9 -27 20 18 21 18 14 -1 -7 -19 -7 -19
15 0 18 17 21 17 15 3 -3 -9 -2 -17 2 -17 17 0 29 15 31 36 2 17 -3 22 -23 22
-13 0 -27 -5 -30 -10 -4 -6 -9 -5 -14 2 -6 9 -11 7 -19 -8z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
